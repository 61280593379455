<template>
  <div class="question-view" @contextmenu.prevent>
    <div class="question-loading" v-if="!ready">Loading... {{ percent }}</div>
    <div
      class="question-text"
      v-if="question.text"
      v-show="ready"
      v-html="question.text"
    ></div>
    <div
      v-if="is_mobile"
      :style="{
        visibility: ready ? 'visible' : 'hidden',
        filter: `brightness(${brightness}) contrast(${contrast})`,
        'background-image':
          typeof viewer === 'string' && !vid_mobile ? `url(${viewer})` : null,
      }"
      class="viewer mobile"
    >
      <video
        v-if="vid_mobile"
        :src="vid_mobile"
        loop
        controls
        muted
        @mousewheel="video_mwheel"
      ></video>
    </div>
    <div
      v-else
      ref="image-view"
      :style="{
        visibility: ready ? 'visible' : 'hidden',
        filter: `brightness(${brightness}) contrast(${contrast})`,
      }"
      class="viewer"
      @mousedown="canvas_mdown"
      @mousemove="canvas_mmove"
      @mousewheel="canvas_update_pos"
    >
      <canvas ref="canvas" width="1280" height="720"></canvas>
    </div>
    <div class="vidbar" v-if="video">
      <div class="ppform pause-play">
        <button @click="vid_pause_play()">
          <i class="fas fa-play" v-if="vid_paused"></i>
          <i class="fas fa-pause" v-else></i>
        </button>
      </div>
      <div class="ppform seeker">
        <input
          type="range"
          min="0"
          :max="vid_seek_max"
          step="0.01"
          v-model="vid_seek_cur"
        />
      </div>
      <div class="ppform speed">
        Speed:
        <button @click="vid_slower">
          <i class="fas fa-minus"></i>
        </button>
        {{ rate_float.toFixed(1) }}
        <button @click="vid_faster">
          <i class="fas fa-plus"></i>
        </button>
        <button :class="{ toggle: !vid_seek_mode }" @click="vid_manual_seek">
          <i class="fas fa-search"></i>
        </button>
      </div>
    </div>
    <div class="bar" v-if="src.length >= 1" v-show="ready">
      <div class="btn-group">
        <button
          v-for="(i, k) in preview_src"
          :key="k"
          :style="{ 'background-image': `url(${i})` }"
          @click="select(k)"
          :class="{ active: selected == k }"
        ></button>
      </div>
      <div class="ppform canvas-controls" v-if="!is_mobile">
        <button @click="canvas_clear">
          <i class="fas fa-trash"></i>
        </button>
      </div>
      <div class="filter-reset ppform">
        <button @click="reset_filter()">Reset</button>
      </div>
      <div class="filter">
        B
        <input
          type="range"
          v-model="brightness"
          min="0.2"
          max="4"
          step="0.01"
        /><br />
        C
        <input type="range" v-model="contrast" min="0.2" max="4" step="0.01" />
      </div>
    </div>
  </div>
</template>

<script>
import ImageViewer from "iv-viewer";
import "iv-viewer/dist/iv-viewer.css";
import axios from "axios";
import api from "@/api";

function is_iOS() {
  // https://stackoverflow.com/a/58065241
  return (
    /iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
  );
}

let canvas_state = {
  pen: null,
  last_coord: null,
};

const rate_step = [0.1, 0.2, 0.4, 0.6, 0.8, 1.0, 1.2, 1.5, 1.7, 2.0, 3.0];
const seek_scroll_step = 1 / 4;
const vid_start_autoplay = false;

export default {
  name: "question-view",
  data() {
    return {
      selected: 0,
      viewer: null,
      loading: true,
      progress: [],
      contrast: 1,
      brightness: 1,

      vid_thumbnail: {},
      video: null,
      vid_seek_cur: 0,
      vid_seek_max: 0,
      vid_rate: 5,
      vid_paused: true,
      vid_seek_mode: true,
      vid_mobile: null,

      is_mobile: is_iOS(),
    };
  },
  props: {
    question: Object,
    lock: Boolean,
  },
  computed: {
    preview_src() {
      if (!this.question.images) return [];
      return this.question.images.map((i) => {
        return this.vid_thumbnail[i] || "/api/res/" + i;
      });
    },
    src() {
      if (!this.question.images) return [];
      return this.question.images.map((i) => "/api/res/" + i);
    },
    ready() {
      return !this.loading && !this.lock;
    },
    percent() {
      if (!this.progress.length) return "";
      let sum_total = 0;
      let sum_loaded = 0;
      for (const [loaded, total] of this.progress) {
        sum_total += total;
        sum_loaded += loaded;
      }
      if (sum_total == 0) return "";
      return Math.floor((sum_loaded / sum_total) * 100) + "%";
    },
    rate_float() {
      return rate_step[this.vid_rate];
    },
  },
  mounted() {
    if (this.question.images) {
      let load_count = this.src.length;
      const onload = () => {
        if (--load_count == 0) {
          this.$emit("load");
          if (!this.is_mobile)
            this.viewer = new ImageViewer(this.$refs["image-view"]);
          this.loading = false;
          this.video_load();
          this.select(0);
          this.$nextTick(() => {
            window.dispatchEvent(new Event("resize"));
          });
        }
      };
      this.progress = [];
      for (const [k, i] of this.src.entries()) {
        let reload_count = 0;
        const progress_index = this.progress.length;
        if (i.endsWith(".mp4") || i.endsWith(".webm")) {
          let flag = 0;
          let vid = document.createElement("video");
          let generate_thumb = () => {
            let canv = document.createElement("canvas");
            [canv.width, canv.height] = [vid.videoWidth, vid.videoHeight];
            let ctx = canv.getContext("2d");
            ctx.drawImage(vid, 0, 0, canv.width, canv.height);
            this.$set(
              this.vid_thumbnail,
              this.question.images[k],
              canv.toDataURL()
            );
            if (++flag == 2) onload();
          };
          vid.src = i;
          vid.preload = "auto";
          vid.oncanplaythrough = generate_thumb;
          this.progress.push([0, 0]);
          axios
            .get(i, {
              onDownloadProgress: (progressEvent) => {
                this.$set(this.progress, progress_index, [
                  progressEvent.loaded,
                  progressEvent.total,
                ]);
              },
            })
            .then(() => {
              if (++flag == 2) onload();
            });
          vid.onerror = () => {
            flag = 1;
            vid.src = i + "?reload=" + ++reload_count;
          };
        } else {
          let img = new Image();
          img.src = i;
          img.onload = onload;
          this.progress.push([0, 0]);
          axios.get(i, {
            onDownloadProgress: (progressEvent) => {
              this.$set(this.progress, progress_index, [
                progressEvent.loaded,
                progressEvent.total,
              ]);
            },
          });
          img.onerror = () => {
            img.src = i + "?reload=" + ++reload_count;
          };
        }
      }
      this.canvas_load();
    } else {
      this.$emit("load");
      this.loading = false;
    }
  },
  beforeDestroy() {
    this.video_save();
    this.canvas_save();
  },
  methods: {
    reset_filter() {
      this.contrast = 1;
      this.brightness = 1;
    },
    select(k) {
      this.video_save();
      this.canvas_save();
      this.selected = k;
      if (this.is_mobile) {
        this.viewer = this.preview_src[k];
        // TODO: recheck on ipad
        if (this.question.images[k] in this.vid_thumbnail) {
          this.vid_mobile = this.src[k];
        } else this.vid_mobile = null;
      } else {
        this.viewer.load(this.preview_src[k]);

        if (this.question.images[k] in this.vid_thumbnail) {
          let e = this.$refs["image-view"].querySelector(
            ".iv-image.iv-small-image"
          );
          let vid = document.createElement("video");
          vid.autoplay = vid_start_autoplay;
          vid.muted = true;
          vid.controls = false;
          vid.loop = true;
          vid.src = this.src[k];
          vid.classList = e.classList;
          vid.style.cssText = e.style.cssText;
          e.parentNode.replaceChild(vid, e);
          this.viewer._elements.image = vid; // TODO: cleanup
          vid.addEventListener("wheel", this.video_mwheel);
          this.video = vid;
          this.vid_seek_cur = 0;
          this.vid_paused = !vid_start_autoplay;
          vid.playbackRate = this.rate_float;
          vid.oncanplaythrough = () => {
            this.vid_seek_max = vid.duration;
          };
          vid.ontimeupdate = () => {
            this.vid_seek_cur = vid.currentTime;
          };
        } else {
          this.video = null;
        }
        this.canvas_load();
        this.video_load();
      }
    },
    vid_pause_play() {
      if (this.video.paused) {
        this.video.play();
      } else {
        this.video.pause();
      }
      this.vid_paused = this.video.paused;
    },
    vid_slower() {
      if (this.vid_rate > 0) this.vid_rate--;
    },
    vid_faster() {
      if (this.vid_rate < rate_step.length - 1) this.vid_rate++;
    },
    vid_manual_seek() {
      this.vid_seek_mode = !this.vid_seek_mode;
    },
    video_mwheel(event) {
      if (this.video) {
        if (!event.shiftKey ^ !this.vid_seek_mode) {
          const delta = event.deltaY;
          event.stopPropagation();
          if (delta < 0) {
            this.vid_seek_cur = Math.max(
              this.video.currentTime - seek_scroll_step,
              0
            );
          } else if (delta > 0) {
            this.vid_seek_cur = Math.min(
              this.video.currentTime + seek_scroll_step,
              this.vid_seek_max
            );
          }
        }
      }
    },
    video_save() {
      if (this.video) {
        api.save_annotation_local(
          this.question,
          this.selected + "_frame",
          String(this.video.currentTime)
        );
      }
    },
    video_load() {
      if (this.video) {
        let frame_str = api.load_annotation_local(
          this.question,
          this.selected + "_frame"
        );
        if (!frame_str) return;
        let frame = parseFloat(frame_str);
        if (!Number.isNaN(frame)) this.vid_seek_cur = frame;
      }
    },
    canvas_save() {
      if (!this.is_mobile) {
        const canv = this.$refs["canvas"];
        api.save_annotation_local(
          this.question,
          this.selected,
          canv.toDataURL("image/webp")
        );
        const ctx = canv.getContext("2d");
        ctx.clearRect(0, 0, canv.width, canv.height);
      }
    },
    canvas_load() {
      const self = this;
      let annotation = api.load_annotation_local(this.question, this.selected);
      if (annotation) {
        const canv = this.$refs["canvas"];
        const ctx = canv.getContext("2d");
        let img = new Image();
        img.onload = function() {
          self.canvas_update_pos();
          ctx.drawImage(img, 0, 0);
        };
        img.src = annotation;
      }
    },
    canvas_mdown(event) {
      this.canvas_mevent(event);
    },
    canvas_mmove(event) {
      this.canvas_mevent(event);
    },
    canvas_mevent(event) {
      let pen = null;
      if (event.buttons == 2) {
        // right click
        if (event.shiftKey) pen = "eraser";
        else pen = "pen";
        event.stopPropagation();
      } else if (event.buttons == 4) {
        // middle
        pen = "eraser";
        event.stopPropagation();
      } else if (event.buttons == 1) {
        // dragging
      }
      this.canvas_update_pos();
      this.canvas_set_state(pen, event.clientX, event.clientY);
    },
    canvas_update_pos() {
      if (this.viewer) {
        const canv = this.$refs["canvas"].style;
        const img_element = this.$refs["image-view"].querySelector(".iv-image")
          .style;
        const update_pos =
          canv.left != img_element.left || canv.top != img_element.top;
        const update_size =
          canv.width != img_element.width || canv.height != img_element.height;
        if (update_pos) {
          canv.left = img_element.left;
          canv.top = img_element.top;
        }
        if (update_size) {
          canv.width = img_element.width;
          canv.height = img_element.height;
        }
        if (update_pos || update_size)
          window.requestAnimationFrame(this.canvas_update_pos);
      }
    },
    canvas_set_state(pen, x, y) {
      const canv = this.$refs["canvas"];
      const ctx = canv.getContext("2d");
      const rect = canv.getBoundingClientRect();
      x = ((x - rect.x) * canv.width) / rect.width;
      y = ((y - rect.y) * canv.height) / rect.height;
      if (canvas_state.pen) {
        if (pen) {
          // Continue drawing
          ctx.beginPath();
          ctx.moveTo(canvas_state.last_coord.x, canvas_state.last_coord.y);
          ctx.lineTo(x, y);
          ctx.stroke();
          canvas_state.last_coord2 = canvas_state.last_coord;
        } else {
          // Stop drawing
        }
      } else {
        if (pen) {
          // Start drawing
          ctx.lineJoin = "round";
          ctx.lineCap = "round";
          ctx.strokeStyle = "#ff4141";
          if (pen == "pen") {
            ctx.lineWidth = 3;
            ctx.globalCompositeOperation = "source-over";
          } else if (pen == "eraser") {
            ctx.lineWidth = 50;
            ctx.globalCompositeOperation = "destination-out";
          }
          ctx.beginPath();
          ctx.moveTo(x - 0.1, y - 0.1);
          ctx.lineTo(x, y);
          ctx.stroke();
        }
      }
      canvas_state.last_coord = { x, y };
      canvas_state.pen = pen;
    },
    canvas_clear() {
      const canv = this.$refs["canvas"];
      const ctx = canv.getContext("2d");
      ctx.clearRect(0, 0, canv.width, canv.height);
    },
  },
  watch: {
    vid_seek_cur(val) {
      if (this.video && Math.abs(this.video.currentTime - val) > 1 / 30) {
        this.video.currentTime = val;
      }
    },
    video(cur, old) {
      if (!!cur != !!old) {
        this.$nextTick(() => {
          window.dispatchEvent(new Event("resize"));
        });
      }
    },
    vid_rate() {
      this.video.playbackRate = this.rate_float;
    },
  },
};
</script>

<style>
.question-view {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.question-view canvas {
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}

.question-view .question-text,
.question-view .question-loading {
  margin: 1rem;
  padding: 0 1em;
  border-radius: 0.3rem;
  border: 1px solid #ccc;
  flex: 0 1 auto;
}
.question-view .question-loading {
  padding: 0.5em 1em;
  text-align: center;
  font-size: 2rem;
}

.question-view .viewer {
  flex: 1 1 auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.question-view .bar {
  background-color: #ddd;
  padding: 0.25rem 0;
  text-align: center;
  flex: 0 1 auto;
  display: flex;
}
.question-view .btn-group {
  flex: 1 1 auto;
  font-size: 0;
}
.question-view .bar .filter-reset,
.question-view .bar .filter {
  flex: 0 1 auto;
  background-color: #c3c3c3;
  font-size: 0.8rem;
  padding: 0 0.5rem;
}
.question-view .bar .filter-reset {
  border-left: 1px solid #777;
  padding-right: 0;
}
.question-view .bar .filter input {
  vertical-align: middle;
  height: 1rem;
  width: 10rem;
}
.question-view .bar .filter-reset button {
  width: 3rem;
  height: 2.5rem;
  margin-top: 0.25em;
  padding: 0;
}
.question-view .bar .canvas-controls {
  flex: 0 1 auto;
  font-size: 0.8rem;
  padding: 0 0.3rem;
}
.question-view .bar .canvas-controls button {
  width: 2rem;
  height: 2.5rem;
  margin-top: 0.25em;
  padding: 0;
}
.question-view .bar .btn-group button {
  width: 3rem;
  height: 3rem;
  border: 1px #999 solid;
  background-color: #bbb;
  border-radius: 0.3rem;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  margin-left: 0.25em;
  outline: 0;
  transition: background-color 0.1s, border-color 0.1s;
}
.question-view .bar .btn-group button:first-child {
  margin-left: 0;
}
.question-view .bar .btn-group button:hover {
  border: 1px #888 solid;
  background-color: #aaa;
}
.question-view .bar .btn-group button:active,
.question-view .bar .btn-group button:focus {
  border: 1px #777 solid;
  background-color: #999;
}
.question-view .bar .btn-group button.active {
  border: 1px #333 solid;
  background-color: #999;
}
.question-view .vidbar {
  background-color: #cfcfcf;
  padding: 0.3rem 0.5rem;
  font-size: 0.7em;
  display: flex;
}
.question-view .vidbar .pause-play button {
  padding: 0.7em 1.7em;
}
.question-view .vidbar .seeker {
  flex: 1 1 auto;
  margin: 0 0.5rem;
}
.question-view .vidbar .seeker input {
  margin-top: 0.4rem;
  width: 100%;
}
.question-view .vidbar .speed button {
  padding: 0.7em 0.7em;
}
.viewer.mobile {
  overflow: hidden;
}
.viewer.mobile video {
  height: 100%;
  width: 100%;
}

/* Dark mode */
#exam.dark .question-view .vidbar {
  background-color: #0f0f0f;
}

#exam.dark .question-view .bar {
  background-color: #191919;
}
#exam.dark .question-view .bar .filter-reset,
#exam.dark .question-view .bar .filter {
  background-color: inherit;
}
#exam.dark .question-view .bar .filter-reset {
  border-left: 1px solid #555;
}
#exam.dark .question-view .vidbar button,
#exam.dark .question-view .bar .canvas-controls button,
#exam.dark .question-view .bar .filter-reset button {
  border: 1px #333 solid;
  background-color: #333;
  color: #fff;
}
#exam.dark .question-view .vidbar button:hover,
#exam.dark .question-view .vidbar button:focus,
#exam.dark .question-view .bar .canvas-controls button:hover,
#exam.dark .question-view .bar .canvas-controls button:focus,
#exam.dark .question-view .bar .filter-reset button:hover,
#exam.dark .question-view .bar .filter-reset button:focus {
  background-color: #444;
}
#exam.dark .question-view .vidbar button:active,
#exam.dark .question-view .bar .canvas-controls button:active,
#exam.dark .question-view .bar .filter-reset button:active {
  background-color: #555;
}
#exam.dark .question-view .bar .btn-group button {
  border: 1.6px #000 solid;
  background-color: #000;
}
#exam.dark .question-view .bar .btn-group button:hover,
#exam.dark .question-view .bar .btn-group button:focus {
  border: 1.6px #555 solid;
  background-color: #000;
}
#exam.dark .question-view .bar .btn-group button:active {
  border: 1.6px #888 solid;
  background-color: #000;
}
#exam.dark .question-view .bar .btn-group button.active {
  border: 1.6px rgb(201, 201, 0) solid;
  background-color: #000;
}
#exam.dark .question-view .question-text,
#exam.dark .question-view .question-loading {
  border: 1px solid #888;
}

/* TODO: non-dark mode */
#exam.dark .question-view .vidbar button.toggle {
  background-color: #aaa;
  color: #222;
}
#exam.dark .question-view .vidbar button.toggle:active {
  background-color: #777;
}
#exam.dark .question-view .vidbar button.toggle:hover,
#exam.dark .question-view .vidbar button.toggle:focus {
  background-color: #888;
}
</style>
