import Vue from "vue";
import VueRouter from "vue-router";

import Exam from "@/pages/Exam.vue";
import Login from "@/pages/Login.vue";
import Finish from "@/pages/Finish.vue";

Vue.use(VueRouter);

const routes = [
  { name: "login", path: "/", component: Login },
  { name: "finish", path: "/finish", component: Finish },
  { name: "exam", path: "/exam", component: Exam },
  { name: "exam_node", path: "/exam/:entry", component: Exam },
];

export default new VueRouter({
  mode: "history",
  routes,
});
