<template>
  <main id="finish" class="ppform">
    <h1>
      ระบบได้ทำการบันทึกคำตอบของคุณแล้ว
    </h1>
    <button type="button" class="btn-submit" @click="logout">
      Logout
    </button>
  </main>
</template>

<script>
import "@/assets/forms.css";
import "@fortawesome/fontawesome-free/css/all.css";
import api from "@/api";

export default {
  name: "login",
  data() {
    return {};
  },
  props: {},
  computed: {},
  methods: {
    async logout() {
      api.logout();
    },
  },
};
</script>

<style>
#finish {
  margin: 3em 5em 0;
  text-align: center;
}
#finish h1 {
  font-weight: normal;
}
</style>
