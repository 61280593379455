<template>
  <div class="choices">
    <button
      type="button"
      class="btn-choice"
      v-for="(i, k) in question.choices"
      :key="i"
      :class="btn_style(k)"
      @click="change_ans(k)"
    >
      {{ i }}
    </button>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "answer-mcq",
  data() {
    const ans = api.load_answer_local(this.question);
    return {
      selected: ans ? ans - 1 : -1,
    };
  },
  props: {
    question: Object,
  },
  methods: {
    change_ans(k) {
      this.selected = k;
      api.save_answer_local(this.question, (this.selected + 1).toString());
    },
    btn_style(k) {
      return {
        active: k == this.selected,
        small: this.question.choices[k].length > 2,
      };
    },
    get_answer() {
      if (this.selected == -1) return null;
      return (this.selected + 1).toString();
    },
  },
};
</script>

<style>
.answer-form[data-type="mcq"] .choices .btn-choice {
  margin-bottom: 0.5em;
}
</style>
