<template>
  <nav id="navtop">
    <div class="item">{{ tree.opt.title }}</div>
    <div class="item">{{ stats.submitted }} / {{ stats.count }}</div>
    <div class="item endtime" v-if="endtime != null" :class="timeleft_class">
      {{ timeleft_text }} Left
    </div>
    <div class="right menu">
      <div class="item">{{ user_info.name }}</div>
      <div class="item" v-if="user_info.aux">{{ user_info.aux }}</div>
      <div class="item" v-if="user_info.aux2">{{ user_info.aux2 }}</div>
      <a class="item" @click="logout">Logout</a>
    </div>
  </nav>
</template>

<script>
import { mapState } from "vuex";
import api from "@/api";

export default {
  name: "nav-top",
  props: {
    endtime: Number,
    current_time: Number,
  },
  computed: {
    ...mapState(["tree", "user_info"]),
    stats() {
      return api.count_question(this.tree);
    },
    timeleft_text() {
      return api.format_duration(this.endtime - this.current_time);
    },
    timeleft_class() {
      let sec = Math.ceil(this.endtime - this.current_time);
      return {
        red: sec < 10 * 1000,
      };
    },
  },
  methods: {
    async logout() {
      api.report_answer();
      api.backup_answer();
      api.logout();
    },
  },
};
</script>

<style>
#navtop {
  position: fixed;
  width: 100%;
  height: 3rem;
  border-bottom: 1px rgb(16, 35, 139) solid;
  background: rgb(0, 103, 187);
  color: #fff;
  display: flex;
}
#navtop:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
#navtop .right.menu {
  display: flex;
  margin-left: auto !important;
}
#navtop .item {
  position: relative;
  vertical-align: middle;
  line-height: 1;
  text-decoration: none;
  flex: 0 0 auto;
  padding: 1em 1.1em;
  transition: background 0.1s ease, box-shadow 0.1s ease, color 0.1s ease;
}
#navtop .item:before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  height: 100%;
  width: 1px;
  background: rgba(255, 255, 255, 0.15);
}
#navtop a.item {
  cursor: pointer;
  user-select: none;
}
#navtop a.item:hover {
  background: rgba(255, 255, 255, 0.13);
}
#navtop a.item:active,
#navtop a.item:focus {
  background: rgba(255, 255, 255, 0.329);
}

#navtop .endtime.red {
  background: rgb(192, 39, 39);
  font-weight: bold;
}
</style>
