<template>
  <div id="exam" class="dark">
    <nav-top :endtime="current_endtime" :current_time="current_time" />
    <div id="main-window" @contextmenu.prevent>
      <sidebar :lock="lock" />
      <main id="content" :class="['orientation-' + current_orientation]">
        <article id="question">
          <template v-if="cur_node && started && cur_node.type != 'group'">
            <question-view
              v-if="question && !question.preload"
              :question="question"
              :key="question.entry"
              @load="start_question(question)"
              v-show="!instruction"
            />
            <div
              class="question-view"
              v-else
              v-show="!instruction"
              :lock="lock"
            >
              <div class="question-loading">Loading...</div>
            </div>
          </template>
          <div class="pending" v-if="cur_node && !started">
            <p>Will begin in</p>
            <p>{{ pending_text }}</p>
          </div>
          <div
            class="pending"
            v-if="cur_node && started && cur_node.type == 'group'"
          >
            <p>Choose next part</p>
            <ul v-if="cur_node.children">
              <template v-for="child in cur_node.children">
                <li :key="child.index" v-if="!child._finished">
                  <router-link
                    :to="{
                      name: 'exam_node',
                      params: { entry: get_grp_question_node(child) },
                    }"
                    replace
                  >
                    {{ link_title(child) }}</router-link
                  >
                </li></template
              >
            </ul>
          </div>
          <div
            class="instruction"
            v-if="instruction"
            v-html="instruction.data"
          ></div>
        </article>
        <div id="answer">
          <form
            v-if="question && !question.preload && !lock"
            class="answer-form ppform"
            :data-type="question ? question.type : ''"
            action
            @submit.prevent="submit_answer(true)"
            :key="question.entry"
            v-show="!instruction"
          >
            <div class="answer-inputs">
              <answer-mcq
                v-if="question.type == 'mcq'"
                :question="question"
                ref="answer"
              />
              <answer-essay
                v-if="question.type == 'essay'"
                :question="question"
                ref="answer"
              />
              <answer-multi-essay
                v-if="question.type == 'multi-essay'"
                :question="question"
                :lock="lock"
                ref="answer"
              />
            </div>
            <div class="answer-controls">
              <button
                type="submit"
                class="btn-submit"
                :class="{
                  'just-submitted': just_submitted_timeout !== null,
                }"
                v-if="cur_node.early || question.type != 'none'"
              >
                {{
                  cur_node["submit-text"] ||
                  (just_submitted_timeout !== null ? "Submitted" : "Submit")
                }}
              </button>
              <button
                type="button"
                class="btn-secondary"
                @click="goto_next_node"
                v-if="cur_node.skip != 0 && skip_target != cur_node"
              >
                {{ cur_node["skip-text"] || "Skip" }}
                <template v-if="cur_node.skip >= 0"
                  >({{ cur_node.skip }})</template
                >
              </button>
              <button
                type="button"
                class="btn-ternary"
                @click="finish_pending(true)"
                v-if="pending_submit"
              >
                Finish
                {{ finish_group_title() }}
              </button>
            </div>
          </form>
          <form
            v-if="instruction && cur_node && cur_node.type != 'group'"
            class="ppform instruction"
          >
            <div>
              <button
                type="submit"
                class="btn-submit"
                @click="show_instruction(null)"
              >
                Close instruction
              </button>
            </div>
          </form>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import QuestionView from "@/components/QuestionView.vue";
import AnswerMcq from "@/components/AnswerMcq.vue";
import AnswerEssay from "@/components/AnswerEssay.vue";
import AnswerMultiEssay from "@/components/AnswerMultiEssay.vue";
import NavTop from "@/components/NavTop.vue";
import Split from "@/lib/split.es.js";
import { mapState, mapMutations } from "vuex";
import api from "@/api";
import "@/assets/forms.css";

const split_opt = {
  gutterSize: 12,
  expandToMin: true,
  onDragEnd: () => {
    window.dispatchEvent(new Event("resize"));
  },
};

export default {
  name: "exam",
  data() {
    return {
      current_entry: null,
      current_time: api.now(),
      timeout: null,
      lock: false,
      remember_confirm: {},
      next_heartbeat: 0,
      autohid: false,
      just_submitted_timeout: null,
      backup_handler: null,
      current_orientation: "horizontal",
      sidebar_state: {
        instance: null,
        horizontal: [20, 80],
        vertical: [0, 100],
      },
      answerbar_state: {
        instance: null,
        horizontal: [80, 20],
        vertical: [60, 40],
      },
    };
  },
  components: {
    Sidebar,
    QuestionView,
    AnswerMcq,
    AnswerEssay,
    AnswerMultiEssay,
    NavTop,
  },
  computed: {
    ...mapState([
      "tree",
      "user_info",
      "entry_map",
      "questions",
      "timeoffset",
      "instruction",
    ]),
    node_entry() {
      if (!this.$route.params.entry) return null;
      try {
        const { node } = api.get_node(this.$route.params.entry);
        return node.entry;
      } catch {
        return null;
      }
    },
    question() {
      return this.questions[this.node_entry];
    },
    cur_node() {
      try {
        const { node } = api.get_node(this.node_entry);
        return node;
      } catch {
        return null;
      }
    },
    started() {
      if (!this.cur_node) return null;
      return api.node_started(this.cur_node);
    },
    current_endtime() {
      if (!this.cur_node) return null;
      if (!this.started) return null;
      return this.cur_node.timerange[1] < Infinity
        ? this.cur_node.timerange[1]
        : null;
    },
    pending_text() {
      if (!this.cur_node) return null;
      return api.format_duration(
        this.cur_node.timerange[0] - this.current_time
      );
    },
    pending_submit() {
      if (!this.cur_node) return null;
      return api.node_has_pending_submit(this.cur_node);
    },
    skip_target() {
      if (!this.cur_node) return null;
      return api.get_next_node(this.node_entry);
    },
  },
  created() {
    this.time_tick();
    this.fetch_data();
  },
  mounted() {
    this.update_orientation(this.current_orientation, true);
  },
  destroyed() {
    if (this.backup_handler !== null) {
      clearInterval(this.backup_handler);
      this.backup_handler = null;
    }
    if (this.timeout !== null) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    if (this.just_submitted_timeout !== null) {
      clearTimeout(this.just_submitted_timeout);
      this.just_submitted_timeout = null;
    }
    for (let i of this.split_instance) {
      i.destroy();
    }
  },
  methods: {
    update_orientation(new_orientation, init) {
      if (new_orientation == this.current_orientation && !init) return;
      if (this.sidebar_state.instance === null) {
        this.sidebar_state.instance = Split(["#sidebar", "#content"], {
          sizes: this.sidebar_state[this.current_orientation],
          minSize: [0, 300],
          toggle: 0,
          ...split_opt,
        });
      } else {
        this.sidebar_state[this.current_orientation] =
          this.sidebar_state.instance.getSizes();
        this.sidebar_state.instance.setSizes(
          this.sidebar_state[new_orientation]
        );
      }
      if (this.answerbar_state.instance !== null) {
        this.answerbar_state[this.current_orientation] =
          this.answerbar_state.instance.getSizes();
        this.answerbar_state.instance.destroy();
      }
      this.answerbar_state.instance = Split(["#question", "#answer"], {
        direction: new_orientation == "vertical" ? "horizontal" : "vertical",
        sizes: this.answerbar_state[new_orientation],
        minSize: new_orientation == "vertical" ? [120, 0] : undefined,
        toggle: 1,
        ...split_opt,
      });
      this.current_orientation = new_orientation;
    },
    link_title(child) {
      if (child.opt) {
        const stats = api.count_question(child);
        return (
          api.group_title(child, false, { stat: `(${stats.count})` }) ||
          child.index + 1
        );
      } else return child.title || child.index + 1;
    },
    finish_group_title() {
      return api.group_title(this.pending_submit, true, { stat: "" }) || "part";
    },

    ...mapMutations(["expand_here", "show_instruction"]),
    confirm(msg, reask) {
      if (!reask && this.remember_confirm[msg] !== undefined)
        return this.remember_confirm[msg];
      const ret = confirm(msg);
      if (!ret) this.$set(this.remember_confirm, msg, ret);
      return ret;
    },
    async start_question(question) {
      await api.start_question(question);
      this.lock = false;

      this.update_orientation(question.vertical ? "vertical" : "horizontal");
      if (this.question.type == "none") {
        let hide_answer_area =
          !this.cur_node.early &&
          !(this.cur_node.skip != 0 && this.skip_target != this.cur_node) &&
          !this.pending_submit;
        if (hide_answer_area) {
          if (this.answerbar_state.instance.toggle("hide")) {
            this.autohid = true;
            window.dispatchEvent(new Event("resize"));
          }
        }
      } else if (this.autohid) {
        if (this.answerbar_state.instance.toggle("restore"))
          window.dispatchEvent(new Event("resize"));
        this.autohid = false;
      }
    },
    async finish_pending(reask) {
      for (;;) {
        if (this.pending_submit) {
          let title =
            api.group_title(this.pending_submit, true, { stat: "" }) || "part";
          if (!this.confirm(`Finish ${title}?`, reask)) break;
          await api.finish_group(this.pending_submit);
          continue;
        }
        break;
      }
    },
    show_submitted() {
      if (this.just_submitted_timeout !== null) {
        clearTimeout(this.just_submitted_timeout);
      }
      this.just_submitted_timeout = setTimeout(() => {
        this.just_submitted_timeout = null;
      }, 2000);
    },
    async submit_answer(wait, force_go) {
      // TODO: confirm
      if (this.cur_node.type == "group") return;
      let ans = null;
      const question = this.question;
      if (question.type == "none") ans = "";
      else {
        ans = this.$refs.answer.get_answer(force_go);
      }
      if (ans === null) {
        if (force_go) this.goto_next_node();
        return;
      }
      if (wait) {
        await api.send_answer(this.question, ans);
        this.show_submitted();
        //await this.finish_pending();
      } else api.send_answer(this.question, ans);
      if (question.entry == this.cur_node.entry) this.goto_next_node();
    },
    time_tick() {
      this.current_time = api.now() + this.timeoffset;
      api.time_tick();
      if (
        !this.lock &&
        this.cur_node &&
        api.node_started(this.cur_node) &&
        !api.node_free_skip(this.cur_node)
      ) {
        if (this.cur_node.type == "group") {
          this.goto_next_node();
        } else if (!api.node_available(this.cur_node)) {
          const stat = api.count_question(this.cur_node);
          if (!stat.submitted) {
            if (this.question.type == "none" && !this.cur_node.counted) {
              // When force-finish submit group first, need to prevent it submit answer again
              this.goto_next_node();
            } else this.submit_answer(false, true);
          } else this.goto_next_node();
        }
      }
      if (this.next_heartbeat < this.current_time) {
        api.send_heartbeat();
        // api.sync_time();
        this.next_heartbeat =
          this.current_time + (30000 + Math.random() * 10000);
      }
      this.timeout = setTimeout(this.time_tick, 200);
    },
    get_grp_question_node(node) {
      let entry = node.entry;
      const nq = api.get_next_node(entry, true);
      if (nq) return nq.entry;
      return null;
    },
    goto_next_node() {
      let goto_node = (new_node) => {
        if (new_node.entry != this.node_entry)
          this.$router.replace({
            name: "exam_node",
            params: { entry: new_node.entry },
          });
      };
      let entry = this.tree.entry;
      if (this.cur_node) entry = this.node_entry;
      else {
        const ns = api.get_started_node();
        if (ns) {
          goto_node(ns);
          return;
        }
      }
      const nq = api.get_next_node(entry);
      if (nq) goto_node(nq);
      else {
        const ng = api.get_next_pending_node();
        if (ng) {
          goto_node(ng);
        } else {
          api.report_answer();
          api.backup_answer();
          this.$router.replace({
            name: "finish",
          });
        }
      }
    },
    async load_question() {
      try {
        await api.load_question(this.node_entry);

        const check = (node) =>
          node && node.type != "group" && node != this.cur_node;
        let preload_node = this.skip_target;
        if (!check(preload_node))
          preload_node = api.get_preload_target(this.node_entry);
        if (check(preload_node)) {
          try {
            api.preload_question(preload_node.entry);
          } catch {
            // pass
          }
        }
      } catch {
        alert("Question load error. Please refresh this page [F5].");
      }
    },
    async fetch_data() {
      const entry = this.$route.params.entry;

      // Clear old clutter
      if (this.just_submitted_timeout !== null) {
        clearTimeout(this.just_submitted_timeout);
        this.just_submitted_timeout = null;
      }

      this.lock = true;
      api.load_user_info();
      await api.load_tree();
      const { node } = api.get_node(entry || this.tree.entry);
      let ok = true;
      if (node) {
        if (api.node_started(node)) {
          if (node.type == "group" && !api.node_free_skip(node)) {
            ok = false;
          } else {
            if (!api.node_available(node)) ok = false;
          }
        }
      } else ok = false;
      if (!ok) {
        this.lock = false;
        this.goto_next_node();
        return;
      }
      if (node.entry != entry) {
        // Root node
        this.lock = false;
        this.$router.replace({
          name: "exam_node",
          params: { entry: node.entry },
        });
      }
      if (this.current_entry)
        this.expand_here({
          entry: this.current_entry,
          expand: false,
        });
      this.expand_here({
        entry: node.entry,
        expand: true,
      });
      if (node.type == "group") {
        this.lock = false;

        const { path } = api.get_node(node.entry);
        path.push(node);
        path.reverse();
        for (const e of path) {
          if (e.opt.instruction) {
            this.show_instruction(e);
            break;
          }
        }
      } else {
        if (this.instruction !== null) this.show_instruction(null);
        this.load_question();
        api.backup_answer();
      }
      this.current_entry = node.entry;

      // Start backup task
      if (this.backup_handler !== null) clearInterval(this.backup_handler);
      this.backup_handler = setInterval(api.backup_answer, 30 * 1000);
    },
  },
  watch: {
    $route: "fetch_data",
  },
};
</script>

<style>
#exam {
  margin: 0;
  width: 100%;
}

#main-window {
  position: fixed;
  width: 100%;
  top: 3rem;
  bottom: 0;
  display: flex;
}

#content {
  display: flex;
}
#content.orientation-horizontal {
  flex-direction: column;
}
#content.orientation-vertical {
  flex-direction: row;
}
#question {
  background: rgb(255, 255, 255);
  overflow: auto;
}
#question .pending {
  text-align: center;
  font-size: 2rem;
  padding: 0.1rem 0;

  margin: 1rem;
  border-radius: 0.3rem;
  border: 1px solid #ccc;
}
#question .pending p {
  margin: 0.2em;
}
#question .pending ul {
  list-style: none;
  margin: 0 0 0.5em 0;
  padding: 0;
}
#question .pending ul a {
  text-decoration: none;
  color: rgb(47, 110, 226);
}
#question .pending ul a:hover {
  text-decoration: underline;
}
#question .instruction {
  padding: 0 1.5em;

  margin: 1rem;
  border-radius: 0.3rem;
  border: 1px solid #ccc;
}
#answer {
  background: rgb(255, 255, 255);
  overflow: auto;
}
#answer .instruction {
  padding: 1em;
  width: 100%;
  text-align: center;
}
#answer .instruction button {
  font-size: 1.2rem;
}
.gutter {
  background-color: #eee;
  background-repeat: no-repeat;
  background-position: 50%;
  transition: background-color 0.1s;
}
.gutter:hover {
  background-color: #bbb;
}
.gutter:active {
  background-color: #aaa;
}
.gutter.gutter-horizontal {
  background-image: url("../assets/img/grips/vertical.png");
  cursor: col-resize;
}

.gutter.gutter-vertical {
  background-image: url("../assets/img/grips/horizontal.png");
  cursor: row-resize;
}

#content.orientation-vertical .answer-form {
  padding: 0 1rem;
}
#content.orientation-horizontal .answer-form {
  display: flex;

  padding: 1em;
  height: 100%;
  text-align: center;
}
.answer-form {
  width: 100%;
}
#content.orientation-horizontal .answer-form .answer-controls {
  flex: 0 1 auto;
  flex-direction: column;
  margin: -0.3rem 1rem -0.3rem 1rem;
}
.answer-form .answer-controls {
  display: flex;
  margin: 1rem 0;
}
#content.orientation-vertical .answer-form .answer-controls button {
  margin: 0 0.3rem;
}
.answer-form .answer-controls button {
  margin: 0.3rem 0;
  min-height: 2.1em;
  flex: 0 1 auto;
}
#content.orientation-horizontal .answer-form .answer-inputs {
  flex: 1 1 auto;
  width: 30em;
}
.answer-form[data-type="none"] {
  /* None */
}
.ppform .btn-submit {
  transition: background-color 1s ease;
}
.ppform .btn-submit.just_submitted {
  transition: background-color 0s ease;
  background-color: rgb(0, 51, 0);
}

/* Dark mode */

#exam.dark {
  color: #eee;
}
#exam.dark #question {
  background: #282828;
}
#exam.dark #question .pending,
#exam.dark #question .instruction {
  border: 1px solid #666;
}
#exam.dark #question .pending ul a {
  color: rgb(76, 133, 240);
}
#exam.dark #answer {
  background: #282828;
}
#exam.dark .gutter {
  background-color: #373737;
}
#exam.dark .gutter:hover {
  background-color: #494949;
}
#exam.dark .gutter:active {
  background-color: #666666;
}
#exam.dark .ppform .btn-submit.just-submitted {
  background-color: rgb(133, 156, 0);
}
</style>
