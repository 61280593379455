<template>
  <div>
    <div v-for="(subq, k) in question.subquestion" v-show="!lock" :key="k">
      <div class="question-text" v-html="subq"></div>
      <textarea
        class="form-control field"
        type="text"
        rows="4"
        placeholder="Answer"
        v-model="ans[k]"
        ref="textarea"
        height="auto"
        @input="update_height(k)"
        :autofocus="k == 0"
        @contextmenu.stop
      ></textarea>
    </div>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "answer-multi-essay",
  data() {
    const local_ans = api.load_answer_local(this.question);
    const num_subq = this.question.subquestion.length;
    let ans;
    try {
      ans = JSON.parse(local_ans);
    } catch (_) {
      ans = [];
    }
    if (!Array.isArray(ans)) ans = [];
    while (ans.length < num_subq) ans.push("");
    return {
      ans: ans,
    };
  },
  props: {
    question: Object,
    lock: Boolean,
  },
  methods: {
    get_answer(force) {
      const is_empty = this.ans.every((e) => e == "");
      if (!force && is_empty && !confirm("Submit empty answer?")) return null;
      return JSON.stringify(this.ans);
    },
    update_height(k) {
      const e = this.$refs.textarea[k];
      e.style.height = "";
      e.style.height = e.scrollHeight + "px";
    },
  },
  watch: {
    ans() {
      const json = JSON.stringify(this.ans);
      api.save_answer_local(this.question, json);
    },
  },
  mounted() {
    this.$nextTick(() => {
      for (const k in this.question.subquestion) this.update_height(k);
      this.$refs.textarea[0].focus();
    });
  },
};
</script>

<style>
.answer-form[data-type="multi-essay"] .answer-inputs textarea {
  width: 100%;
  overflow: hidden;
}
.answer-form .question-text {
  margin: 0.5rem;
  text-align: left;
}
</style>
