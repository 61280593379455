<template>
  <nav id="sidebar">
    <ul class="tree-list">
      <li v-if="tree.opt.instruction">
        <div
          class="tree-item-label instruction"
          :class="{
            clickable: true,
            selected: instruction && tree.entry == instruction.entry,
            disabled: !true,
          }"
          @click="click_instruction()"
        >
          <span>&#9702;</span> {{ tree.opt.instruction_title || "Instruction" }}
        </div>
      </li>
      <sidebar-tree-item
        v-for="node in tree.children"
        :node="node"
        :key="node.index"
        :lock="lock"
      />
    </ul>
  </nav>
</template>

<script>
import SidebarTreeItem from "@/components/SidebarTreeItem.vue";
import { mapState, mapMutations } from "vuex";

export default {
  name: "sidebar",
  computed: {
    ...mapState(["tree", "instruction"]),
  },
  props: {
    lock: Boolean,
  },
  components: {
    SidebarTreeItem,
  },
  methods: {
    ...mapMutations(["show_instruction"]),
    click_instruction() {
      if (this.instruction && this.instruction.entry == this.tree.entry) {
        this.show_instruction(null);
      } else this.show_instruction(this.tree);
    },
  },
};
</script>

<style>
#sidebar {
  top: 0;
  height: 100%;
  background: rgb(40, 40, 40);
  color: #fff;
  overflow: auto;
  padding-bottom: 2rem;
}
#sidebar > .tree-list {
  margin-top: 0.5em;
  margin-left: 0.5em;
  min-width: 10em;
}
.sidebar-header {
  text-align: center;
}
.sidebar-header h3 {
  font-size: 1.5em;
  margin: 0.25em 0;
}
</style>
